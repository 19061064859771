//------------------ COLORS -----------------
const COLOR_WHITE = '#FFF';
const COLOR_BLACK = '#000';
const COLOR_GOLD = '#B59660';
const COLOR_GOLD_DARK = '#6F5F44';
const COLOR_GOLD_LIGHT = '#D2B27C';
const COLOR_WHEAT = '#F4F0E5';
const COLOR_WHEAT_DARK = '#F1EAD7';
const COLOR_GRAY = '#3E3E3F';
const COLOR_GRAY_DARK = '#282828';
const COLOR_DARKEST = '#1E1E1E';
const COLOR_GRAY_LIGHT = '#A8A89D';
const COLOR_GRAYISH_DARK = '#787760';

//------------------ FONTS -----------------
const FONT_FAMILY = 'Lato';
// const FONT_SIZE_BASE = 12;

module.exports = {
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_GOLD,
  COLOR_GOLD_DARK,
  COLOR_GOLD_LIGHT,
  COLOR_WHEAT,
  COLOR_WHEAT_DARK,
  COLOR_GRAY,
  COLOR_GRAY_DARK,
  COLOR_GRAY_LIGHT,
  COLOR_GRAYISH_DARK,
  FONT_FAMILY,
  COLOR_DARKEST,
};
