import { createGlobalStyle } from '@nfront/global-styles';
import breakpoint from 'styled-components-breakpoint';

import 'animate.css/animate.min.css';

// Fix IE 11 (and 10) issues by polyfilling the proxy feature from es6
import 'proxy-polyfill/proxy.min.js';

const globalStyles = createGlobalStyle`
  html {
    // background-color: black;
    /* overflow: inherit !important;  */
    /* position: fixed;
    overflow-y:auto; */
  }
  
  body {
    height: 100%;
    overflow: auto;
    font-family: ${props => props.theme.fontFamily};
    /* position: fixed;
    overflow: hidden; */
  }

  .LeftContainer{
    display: flex;
    align-items: center;
  }

  @-webkit-keyframes autofill {
    to {
        color: ${props => props.theme.color.primary.main};
        background: transparent;
    }
  }

  @-webkit-keyframes autofill-disabled {
    to {
        color: ${props => props.theme.color.secondary.light};
        background: transparent;
    }
  }

  input:-webkit-autofill {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
  }

  input[disabled]:-webkit-autofill {
      -webkit-animation-name: autofill-disabled;
      -webkit-animation-fill-mode: both;
  }

  .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
  }
  
  .limousine-modal {
     position: absolute;
     width: 95%;
     top: 1%;
     left: 2%;
     /* bottom: 0; */
     border: 0px;
     background: rgb(1, 1, 1);
     /* overflow: auto; */
     outline: none;
     padding: 20px;
     z-index: 1001;
     transform-origin: 149px 388px;
  }

  .limousine-overlay{
    background-color: rgba(1, 1, 1, 0.45);
    z-index: 1000;
    overflow: auto;
    -webkit-overflow-scrolling : touch;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  ${breakpoint('lg')`
    // .ReactModal__Body--open {
    //   padding-right: 15px;
    // }

    .limousine-modal {
      position: fixed;
      width: 65%;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      border: 0px;
      background: rgb(1, 1, 1);
      z-index: 1001;
      transform-origin: 149px 388px;
    }
  `}
`;

export default globalStyles;
