import { ITheme } from './Type';
const {
  COLOR_WHITE,
  COLOR_GOLD,
  COLOR_GOLD_DARK,
  COLOR_GOLD_LIGHT,
  COLOR_WHEAT,
  COLOR_WHEAT_DARK,
  COLOR_GRAY,
  COLOR_GRAY_LIGHT,
  COLOR_GRAY_DARK,
  COLOR_GRAYISH_DARK,
  COLOR_BLACK,
  COLOR_DARKEST,
  FONT_FAMILY,
} = require('./ThemeVars.tsx');

export const theme = {
  fontFamily: FONT_FAMILY,
  color: {
    primary: {
      main: COLOR_GOLD,
      dark: COLOR_GOLD_DARK,
      light: COLOR_GOLD_LIGHT,
    },
    secondary: { main: COLOR_GRAYISH_DARK, light: COLOR_GRAY_LIGHT, dark: '' },
    text: {
      main: COLOR_WHEAT,
      light: COLOR_WHITE,
      dark: COLOR_WHEAT_DARK,
      black: COLOR_BLACK,
      gray: COLOR_GRAY_DARK,
    },
    background: {
      main: COLOR_GRAY,
      light: COLOR_WHITE,
      dark: COLOR_GRAY_DARK,
      black: COLOR_DARKEST,
    },
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
};
export default theme as ITheme;
